import React, { FC } from "react";
import { LinkBar, LinkBarProps } from "./link-bar";

export const LinkBarContainer: FC = () => {
  const props: LinkBarProps = {
    linkList: [
      {
        text: "Serviços",
        link: "relevantServices",
      },
      {
        text: "Notícias",
        link: "newsContainer",
      },
      {
        text: "Indicadores",
        link: "indicators",
      },
      {
        text: "Aplicativos",
        link: "appsContainer",
      },
      {
        text: "Categorias",
        link: "category",
      },
      {
        text: "Central do Cidadão",
        link: "ombudsman",
      },
      {
        text: "Agendamentos",
        link: "https://dev.portal.mt.gov.br/app/agendamento-de-servicos",
      },
    ],
  };
  return <LinkBar {...props} />;
};
